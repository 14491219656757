import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../utils/globals.css"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import {
  LayoutDataInterface,
  LayoutInterface,
} from "../@types/components/layout"
import tw from "twin.macro"

const LayoutContainer = styled.div`
  ${tw` flex flex-col items-center text-color5`};
`

const Header = styled.header`
  ${tw`w-11/12`};
`
const Nav = styled.nav`
  ${tw`w-full flex font-menu text-xl pt-2 justify-between border-b-2`};
`

const HomeLink = styled.a`
  ${tw`uppercase lg:hover:underline`}
`

const Menu = styled(FontAwesomeIcon)`
  ${tw`lg:hidden`};
  height: inherit;
`

const Main = styled.main`
  ${tw`mt-2 w-11/12 font-content flex flex-col`};
`

const Footer = styled.footer`
  ${tw`flex flex-col items-center mt-10 font-footer`};
`

const FooterLink = styled.a`
  ${tw`hover:underline`};
`

const Layout: React.FC<LayoutInterface> = ({ children }) => {
  const data: LayoutDataInterface = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "license.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const [isExpanded, toggleExpansion] = useState(false)
  const NavigationList = styled.div`
    ${isExpanded ? tw`flex text-4xl` : tw`hidden`};
    ${tw`
      z-10 fixed
      flex-col lg:flex-row 
      lg:flex lg:w-auto lg:mt-0 lg:relative lg:border-0
      mt-8 w-11/12
      text-center bg-color1
      h-full
      `};
  `

  const NavLink = styled(Link)`
    ${isExpanded ? tw`border-b mt-0` : tw`border-b-0 mt-4`};
    ${tw`
      lg:ml-5 hover:lg:underline  lg:mt-0
      `};
  `

  const A = styled.a`
    ${isExpanded ? tw`border-b mt-0` : tw`border-b-0 mt-4`};
    ${tw`
      lg:ml-5 hover:lg:underline  lg:mt-0
      `};
  `

  /**
   * Installed version of tailwind doesn't seem to support last:. Hacky way of setting bottom margin on last navlink
   */
  const LastA = styled(A)`
    ${isExpanded ? tw`mb-0` : tw`mb-4`};
  `
  return (
    <LayoutContainer>
      <Helmet>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Ryan's Namepage RSS Feed"
          href="//ryankes.eu/rss.xml"
        />
      </Helmet>
      <Header>
        <Nav>
          <HomeLink href="//ryankes.eu">
            {data.site.siteMetadata.title}
          </HomeLink>
          <Menu
            icon={!isExpanded ? faBars : faTimesCircle}
            onClick={() => toggleExpansion(!isExpanded)}
          />
          <NavigationList>
            <A href="//ryankes.eu/posts">Blog</A>
            <A href="//ryankes.eu/projects">Projects</A>
            <A href="//ryankes.eu/wiki">Wiki</A>
            <NavLink to={"/"}>Tunes</NavLink>
            <A href="//ryankes.eu/about">About</A>
            <A href="//ryankes.eu/hire-me">Hire me</A>
            <LastA href="//ryankes.eu/contact">Contact</LastA>
          </NavigationList>
        </Nav>
      </Header>
      <Main>{children}</Main>
      <Footer>
        {data.placeholderImage ? (
          <a
            href="//creativecommons.org/licenses/by-nc-sa/4.0/"
            rel={"license"}
          >
            <Img
              fixed={data.placeholderImage.childImageSharp.fixed}
              title={
                "Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0) "
              }
            />
          </a>
        ) : (
          ""
        )}
        <div>
          <FooterLink href="//ryankes.eu/terms-of-service/">
            Terms Of Service
          </FooterLink>
          {"  "}-{"  "}
          <FooterLink href="//ryankes.eu/privacy-policy/">
            Privacy Policy
          </FooterLink>
        </div>
        <>© {new Date().getFullYear()} Ryan Kes</>
      </Footer>
    </LayoutContainer>
  )
}

export default Layout
