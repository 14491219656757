import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { SEODataInterface, SEOInterface } from "../@types/components/seo"
import { Helmet } from "react-helmet"

function SEO({
  title,
  description,
  image,
  type,
  publishedTime,
}: SEOInterface): React.ReactElement {
  const { site }: SEODataInterface = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            mainSiteUrl
            image {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imageUrl =
    site.siteMetadata.mainSiteUrl +
    site.siteMetadata.image.childImageSharp.fixed.srcWebp

  const metaDescription = description || site.siteMetadata.description
  const metaImageUrl = image ? site.siteMetadata.siteUrl + image : imageUrl
  const metaType = type || "website"

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        publishedTime
          ? {
              name: `article:published_time`,
              content: publishedTime,
            }
          : {},
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImageUrl,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          name: `twitter:card`,
          content: `summary_large_picture`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default SEO
